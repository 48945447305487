import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'softpath-medical';
  disableButton: boolean = false;

  constructor(private router: Router){

  }

  ngOnInit(): void{
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
      
      this.disableButton = window.location.href.includes("refer-friend") ? true : false;
    });
  }

  redirectToHome(): void{
    this.router.navigate(['/'])
  }

  redirectToReferAFriend(): void{
    const currentURL = this.router.url;
    
    if(currentURL != "/refer-friend?returnURL=%2F"){
      this.router.navigate(['/refer-friend'], {
        queryParams: { returnURL: currentURL },
      });
    }
  }
}
